import React, { Component } from 'react';
import img_elRoundimage from '../../images/TeamMember.png';

require('./styles.css');

export default class TeamMember extends Component {

  // Properties used by this component:
  // name, description, image

  render() {
    // eslint-disable-next-line no-unused-vars
    let baseStyle = {};
    // eslint-disable-next-line no-unused-vars
    let layoutFlowStyle = {};
    
    const style_elBackgroundShape = {
        background: 'rgba(255, 255, 255, 1.000)',
     };
    const style_elRoundimage = {
        backgroundImage: 'url('+(this.props.image || img_elRoundimage)+')',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: '50% 50%',
        backgroundSize: 'cover',
     };
    const style_elText = {
        fontSize: 19.4,
        color: 'rgba(0, 0, 0, 0.8500)',
        textAlign: 'center',
     };
    const value_text = this.props.name;
    
    const style_elTextCopy = {
        color: 'rgba(0, 0, 0, 0.8500)',
        textAlign: 'center',
     };
    const value_textCopy = this.props.description;
    
    const style_elSpacer = {
        background: 'rgba(254, 255, 255, 1.000)',
     };
    
    return (
      <div className="TeamMember" style={baseStyle}>
        <div className="background">
          <div className='containerMinHeight elBackgroundShape' style={style_elBackgroundShape} />
        </div>
        <div className="layoutFlow">
          <div className='elRoundimage'>
            <div style={style_elRoundimage} />
          
          </div>
          
          <div className='font-openSansCondensedBold  elText'>
            <div style={style_elText}>
              <div>{value_text !== undefined ? value_text : (<span className="propValueMissing">{this.props.locStrings.teammember_text_1015414}</span>)}</div>
            </div>
          
          </div>
          
          <div className='baseFont elTextCopy'>
            <div style={style_elTextCopy}>
              <div>{value_textCopy !== undefined ? value_textCopy : (<span className="propValueMissing">{this.props.locStrings.teammember_textcopy_404214}</span>)}</div>
            </div>
          
          </div>
          
          <div className='elSpacer'>
            <div style={style_elSpacer} />
          
          </div>
          
        </div>
      </div>
    )
  }
  

}
