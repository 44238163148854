import React, { Component } from 'react';

require('./styles.css');

export default class OurMission extends Component {

  // This component doesn't use any properties

  render() {
    // eslint-disable-next-line no-unused-vars
    let baseStyle = {};
    // eslint-disable-next-line no-unused-vars
    let layoutFlowStyle = {};
    
    const style_elBackgroundShape = {
        background: 'rgba(35, 38, 50, 1.000)',
     };
    const style_elTextBlock = {
        fontSize: 30.1,
        color: '#feffff',
        textAlign: 'center',
     };
    
    return (
      <div className="OurMission" style={baseStyle}>
        <div className="background">
          <div className='containerMinHeight elBackgroundShape' style={style_elBackgroundShape} />
        </div>
        <div className="foreground">
          <div className='font-openSansCondensedBold  elTextBlock' style={style_elTextBlock}>
            <div>{this.props.locStrings.ourmission_text}</div>
          </div>
        </div>
      </div>
    )
  }
  

}
