import React, { Component } from 'react';

require('./styles.css');

export default class Slogantop extends Component {

  // This component doesn't use any properties

  render() {
    // eslint-disable-next-line no-unused-vars
    let baseStyle = {};
    // eslint-disable-next-line no-unused-vars
    let layoutFlowStyle = {};
    
    const style_elBackgroundShape = {
        //background: 'rgba(35, 40, 52, 1.000)',
        background: 'rgba(59, 82, 164, 1.000)',
     };
    const style_slogantop_line1 = {
        fontSize: 40.1,
        color: '#feffff',
        textAlign: 'center',
     };
    const style_slogantop_line2 = {
        fontSize: 20.6,
        fontStyle: 'italic',
        color: '#feffff',
        textAlign: 'center',
     };
    
    return (
      <div className="Slogantop" style={baseStyle}>
        <div className="background">
          <div className='containerMinHeight elBackgroundShape' style={style_elBackgroundShape} />
        </div>
        <div className="layoutFlow">
          <div className='font-openSans  slogantop_line1'>
            <div style={style_slogantop_line2}>
                {this.props.locStrings.slogan_knowledge_is_power}
            </div>
          </div>

          {/*<div className='font-haloHandletter  slogantop_line2'>*/}
          {/*  <div style={style_slogantop_line2}>*/}
          {/*    <div>{this.props.locStrings.i29_slogan}</div>*/}
          {/*  </div>*/}
          {/*</div>*/}
          
        </div>
      </div>
    )
  }
  

}
