import React, { Component } from 'react';

require('./styles.css');

export default class WhatWeDo extends Component {

  // This component doesn't use any properties

  render() {
    // eslint-disable-next-line no-unused-vars
    let baseStyle = {};
    // eslint-disable-next-line no-unused-vars
    let layoutFlowStyle = {};
    
    const style_elBackgroundShape = {
        background: 'rgba(255, 255, 255, 1.000)',
     };
    const style_elText = {
        color: 'rgba(0, 0, 0, 0.8500)',
        textAlign: 'left',
     };
    const style_elTextCopy = {
        color: 'rgba(0, 0, 0, 0.8500)',
        textAlign: 'left',
     };
    const style_elSpacer = {
        background: 'rgba(255, 255, 255, 0.000)',
     };
    
    return (
      <div className="WhatWeDo" style={baseStyle}>
        <div className="background">
          <div className='containerMinHeight elBackgroundShape' style={style_elBackgroundShape} />
        </div>
        <div className="layoutFlow">
          <div className='headlineFont elText'>
            <div style={style_elText}>
              <div>{this.props.locStrings.whatwedo_text}</div>
            </div>
          
          </div>
          
          <div className='baseFont elTextCopy'>
            <div style={style_elTextCopy}>
              <div>{this.props.locStrings.whatwedo_details}</div>
            </div>
          
          </div>
          
          <div className='elSpacer'>
            <div style={style_elSpacer} />
          
          </div>
          
        </div>
      </div>
    )
  }
  

}
