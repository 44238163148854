import React, { Component } from 'react';
import svg_Logo from '../../images/svgs/Logo_svg.svg';

require('./styles.css');

export default class Logo extends Component {

  // This component doesn't use any properties

  render() {
    // eslint-disable-next-line no-unused-vars
    let baseStyle = {};
    // eslint-disable-next-line no-unused-vars
    let layoutFlowStyle = {};
    
    const style_elBackgroundShape = {
        background: 'rgba(35, 40, 52, 1.000)'
    };
    const style_elLogowhite = {
      backgroundImage: 'url('+svg_Logo+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 0%',
      backgroundSize: 'contain',
   };
    const style_elRectangle = {
        background: 'rgba(254, 255, 255, 1.000)',
        display: 'none',
     };
    const style_companyHeader = {
      fontFamily: 'ArialMT',
      fontSize: 40.1,
      color: '#79AE64',
      textAlign: 'center',
    };
    
    return (
      <div className="Logo" style={baseStyle}>
        <div className="background">
          <div className='containerMinHeight elBackgroundShape' style={style_elBackgroundShape} />
        </div>
        <div className="foreground">
          <div className='elLogowhite' style={style_elLogowhite} />
          <div className='elCompanyHeader'>
            {this.props.locStrings.i29_company_name}
          </div>
          <div className='elRectangle' style={style_elRectangle} />
        </div>
      </div>
    )
  }
}
