import DataSheetBase from './DataSheetBase.js';

export default class DataSheet_localizationSheet extends DataSheetBase {

  constructor(id, updateCb) {
    super(id, updateCb);
    this.requestedKeyPath = "";  // this value can be specified in the React Studio data sheet UI
  }

  makeDefaultItems() {
    // eslint-disable-next-line no-unused-vars
    let key = 1;
    // eslint-disable-next-line no-unused-vars
    let item;

    item = {};
    this.items.push(item);
    item['key'] = "i29_company_name";
    item['en'] = "iNNOVATION 29";

    item = {};
    this.items.push(item);
    item['key'] = "i29_slogan";
    item['en'] = "Learning is the key";

    item = {};
    this.items.push(item);
    item['key'] = "slogantop_line1";
    item['en'] = "It’s nice to meet you";

    item = {};
    this.items.push(item);
    item['key'] = "slogantop_line2";
    item['en'] = "Welcome to our home";

    item = {};
    this.items.push(item);
    item['key'] = "ourapps";
    item['en'] = "Our Apps";

    item = {};
    this.items.push(item);
    item['key'] = "teammember_text_1015414";
    item['en'] = "Name";

    item = {};
    this.items.push(item);
    item['key'] = "teammember_textcopy_404214";
    item['en'] = "Description";

    item = {};
    this.items.push(item);
    item['key'] = "contactus_header";
    item['en'] = "Contact us";

    item = {};
    this.items.push(item);
    item['key'] = "contactus_sendbutton_558898";
    item['en'] = "Send";

    item = {};
    this.items.push(item);
    item['key'] = "contactus_emailfield_625829";
    item['en'] = "Email";

    item = {};
    this.items.push(item);
    item['key'] = "sociallinks_credits_869374";
    item['en'] = "Image credits: Unsplash.com";

    item = {};
    this.items.push(item);
    item['key'] = "copyright";
    item['en'] = "iNNOVATION 29 LLC. All Rights Reserved.";

    item = {};
    this.items.push(item);
    item['key'] = "whatwedo_text";
    item['en'] = "What we do!";

    item = {};
    this.items.push(item);
    item['key'] = "whatwedo_details";
    item['en'] = "Learning is the Key!!! Since the day we were born, we have been learning something or the other, " +
        "and with each learning our life has gotten better. We have acquired new skills which lead us to a better life. " +
        "People who are aways learning any new thing are like rolling stones, and Rolling Stones gather no moss. " +
        "We at iNNOVATION 29 are aiming to help people to transform to a better tomorrow.";

    item = {};
    this.items.push(item);
    item['key'] = "howwedoit_text";
    item['en'] = "How we do it!";

    item = {};
    this.items.push(item);
    item['key'] = "howwedoit_details";
    item['en'] = "With the experiences, discussions and research we came across interesting patterns, " +
        "and are working on to get the best solution which in return will help the users. ";

    item = {};
    this.items.push(item);
    item['key'] = "ourteam";
    item['en'] = "Meet the team";

    item = {};
    this.items.push(item);
    item['key'] = "ourmission_text";
    item['en'] = "Our mission";

    item = {};
    this.items.push(item);
    item['key'] = "ourpassion_text";
    item['en'] = "Our passion";

    item = {};
    this.items.push(item);
    item['key'] = "menu_home";
    item['en'] = "Home";

    item = {};
    this.items.push(item);
    item['key'] = "privacy_policy";
    item['en'] = "Privacy Policy";

    item = {};
    this.items.push(item);
    item['key'] = "app_privacy_policy";
    item['en'] = "App Privacy Policy";

    item = {};
    this.items.push(item);
    item['key'] = "menu_our_works";
    item['en'] = "Our works";

    item = {};
    this.items.push(item);
    item['key'] = "menu_pricing";
    item['en'] = "Pricing";

    item = {};
    this.items.push(item);
    item['key'] = "menu_contact_us";
    item['en'] = "Contact us";

    item = {};
    this.items.push(item);
    item['key'] = "aboutourpassion_details";
    item['en'] = "We strongly believe in what we do. We aim at doing things in a different way with " +
        "the intentions to make life easier and convenient.";

    item = {};
    this.items.push(item);
    item['key'] = "aboutourpassion_textcopy2_68912";
    item['en'] = "Morbi mattis tortor lectus, vitae interdum enim consequat sit amet. Quisque in arcu libero. Praesent eu elit arcu.\n\n";

    item = {};
    this.items.push(item);
    item['key'] = "map_text_895751";
    item['en'] = "We’re based in Silicon Valley";

    item = {};
    this.items.push(item);
    item['key'] = "slogan_knowledge_is_power";
    item['en'] = "Knowledge is the Power – Learning is the key!";

  }

  getStringsByLanguage = () => {
    let stringsByLang = {};
    for (let row of this.items) {
      const locKey = row.key;
      for (let key in row) {
        if (key === 'key')
          continue;
        let langObj = stringsByLang[key] || {};
        langObj[locKey] = row[key];
        stringsByLang[key] = langObj;
      }
    }
    return stringsByLang;
  }

}
