import React, { Component } from 'react';
import img_elBG from '../../images/ContactUs.gif';

require('./styles.css');

export default class ContactUs extends Component {

  // This component doesn't use any properties

  constructor(props) {
    super(props);
    
    this.state = {
      emailField: '',
    };
  }

  textInputChanged_emailField = (event) => {
    this.setState({emailField: event.target.value});
  };
  
  onClick_elSendButton = (ev) => {
    alert("Sorry but this is just a demo so your email address was not sent to anywhere");
  };
  
  
  render() {
    // eslint-disable-next-line no-unused-vars
    let baseStyle = {};
    // eslint-disable-next-line no-unused-vars
    let layoutFlowStyle = {};
    
    const style_elBG = {
        backgroundImage: 'url('+img_elBG+')',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: '50% 50%',
        backgroundSize: 'cover',
     };
    const style_elEmailField = {
        display: 'block',
        backgroundColor: 'white',
        paddingLeft: '1rem',
        boxSizing: 'border-box', // ensures padding won't expand element's outer size
        color: '#feffff',
        pointerEvents: 'auto',
     };
    
    const style_elSendButton = {
        display: 'block',
        color: '#fff',
        textAlign: 'center',
        backgroundColor: '#242524',
        textTransform: 'none',
        cursor: 'pointer',
        pointerEvents: 'auto',
     };
    const style_elHeader = {
        fontSize: 30.1,
        color: 'rgba(0, 0, 0, 0.8500)',
        textAlign: 'center',
    };
    const style_elEmail = {
        fontSize: 24,
        color: 'rgba(59, 82, 164, 0.8500)',
        textAlign: 'center',
    };

    const style_elPhone = {
        fontSize: 24,
        color: 'rgba(59, 82, 164, 0.8500)',
        textAlign: 'center',
    };
    
    return (
      <div className="ContactUs" style={baseStyle}>
        <div className="background">
          <div className='containerMinHeight elBG' style={style_elBG} />
        </div>
        <div className="foreground">
          <div className='font-openSansCondensedBold  elHeader' style={style_elHeader}>
            <div>{this.props.locStrings.contactus_header}</div>
          </div>
        </div>
        <div className="foreground">
          <div className='font-openSansCondensedBold elEmailField' style={style_elEmail}>
            <div>Email: office@innovation29.com</div>
          </div>
        </div>
        <div className="foreground">
          <div className='font-openSansCondensedBold elPhoneField' style={style_elPhone}>
            <div>Phone: +1-(408)-669-7088</div>
          </div>
        </div>
      </div>
    )
  }
}
