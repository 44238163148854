import React, { Component } from 'react';

require('./styles.css');

export default class AboutOurPassion extends Component {

  // This component doesn't use any properties

  render() {
    // eslint-disable-next-line no-unused-vars
    let baseStyle = {};
    // eslint-disable-next-line no-unused-vars
    let layoutFlowStyle = {};
    
    const style_elBackgroundShape = {
        background: 'rgba(255, 255, 255, 1.000)',
     };
    const style_elTextCopy = {
        color: 'rgba(0, 0, 0, 0.8500)',
        textAlign: 'center',
     };
    const style_elImage = {
        height: 'auto',
     };
    const style_elTextCopy2 = {
        color: 'rgba(0, 0, 0, 0.8500)',
        textAlign: 'left',
     };
    
    return (
      <div className="AboutOurPassion" style={baseStyle}>
        <div className="background">
          <div className='containerMinHeight elBackgroundShape' style={style_elBackgroundShape} />
        </div>
        <div className="layoutFlow">
          <div className='baseFont elTextCopy'>
            <div style={style_elTextCopy}>
              <div>{this.props.locStrings.aboutourpassion_details}</div>
            </div>
          
          </div>
          
        </div>
      </div>
    )
  }
  

}
