import React, {Component} from 'react';

require('./styles.css');

export default class Menu extends Component {

  // This component doesn't use any properties

  onClick_elHotspot = (ev) => {
    alert("home clicked...");
  };

  handleNavigation(page) {
    alert("navigation clicked... " + page);
  }


  render() {
    // eslint-disable-next-line no-unused-vars
    let baseStyle = {};
    // eslint-disable-next-line no-unused-vars
    let layoutFlowStyle = {};

    const style_elBackgroundShape = {
      background: 'rgba(254, 255, 255, 1.000)',
    };
    const style_elLine = {
      borderTop: '1px solid rgba(0, 0, 0, 0.6976)',
    };
    const style_elText = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
    };
    const style_elLineCopy4 = {
      borderTop: '1px solid rgba(0, 0, 0, 0.6976)',
    };
    const style_elTextCopy4 = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
    };
    const style_elLineCopy = {
      borderTop: '1px solid rgba(0, 0, 0, 0.6976)',
    };
    const style_elTextCopy2 = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
    };
    const style_elLineCopy2 = {
      borderTop: '1px solid rgba(0, 0, 0, 0.6976)',
    };
    const style_elTextCopy3 = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
    };
    const style_elLineCopy5 = {
      borderTop: '1px solid rgba(0, 0, 0, 0.6976)',
    };
    const style_elHotspot = {
      display: 'block',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
    };

    return (
      <div className="Menu" style={baseStyle}>
        <div className="background">
          <div className='containerMinHeight elBackgroundShape' style={style_elBackgroundShape}/>
        </div>
        <div className="layoutFlow">
          <div className='elLine'>
            <div style={style_elLine}/>
          </div>

          <div className='headlineFont elText'>
            <div style={style_elText}>
              <div className='actionFont containerMinHeight elHotspot' style={style_elHotspot}
                   onClick={() => this.handleNavigation('home')}>{this.props.locStrings.menu_home} A
              </div>
            </div>
          </div>

          <div className='elLineCopy4'>
            <div style={style_elLineCopy4}/>
          </div>

          <div className='headlineFont elTextCopy4'>
            <div style={style_elTextCopy4}>
              <div>{this.props.locStrings.menu_terms_and_conditions} B</div>
            </div>
          </div>

          <div className='elLineCopy'>
            <div style={style_elLineCopy}/>
          </div>

          <div className='headlineFont elTextCopy2'>
            <div style={style_elTextCopy2}>
              <div>{this.props.locStrings.menu_contact_us} C</div>
            </div>
          </div>

          <div className='elLineCopy2'>
            <div style={style_elLineCopy2}/>
          </div>

          <div className='headlineFont elTextCopy3'>
            <div style={style_elTextCopy3}>
              <div>{this.props.locStrings.menu_pricing} D</div>
            </div>
          </div>

          <div className='elLineCopy5'>
            <div style={style_elLineCopy5}/>
          </div>

        </div>
        {/*<div className="foreground">*/}
        {/*  <div className='actionFont containerMinHeight elHotspot' style={style_elHotspot}  onClick={this.onClick_elHotspot}  />*/}
        {/*</div>*/}
      </div>
    )
  }

}
