import React, { Component } from 'react';
import { Link } from "react-router-dom";


require('./styles.css');

export default class Footer extends Component {

  onClick_elPrivacyPolicy = (ev) => {
    window.open('https://twitter.com/innovation_29', '_blank');
  };

  onClick_elAppPolicy = (ev) => {
    window.open('https://www.youtube.com/channel/UCA1KDPmlhwobPN8tSL6gMCw', '_blank');
  };

  onClick_elLink = (ev) => {
    window.open('https://innovation29.com', '_blank');
  };

  render() {
    // eslint-disable-next-line no-unused-vars
    let baseStyle = {};
    // eslint-disable-next-line no-unused-vars
    let layoutFlowStyle = {};

    const style_elBackgroundShape = {
        background: 'rgba(255, 255, 255, 1.000)',
     };
    const style_elCopyright = {
        color: 'rgba(0, 0, 0, 0.8500)',
        textAlign: 'center',
    };

    return (
      <div className="Footer" style={baseStyle}>
        <div className="background">
          <div className='containerMinHeight elBackgroundShape' style={style_elBackgroundShape} />
        </div>
        <div className="foreground">
          <div className='elPolicy'>
            <div className='elPolicyRow'>
              <Link className='elPolicyLink' to="/privacy-policy/">{this.props.locStrings.privacy_policy}</Link>
            </div>
            <div className='elPolicyRow'>
              <Link className='elPolicyLink' to="/app-privacy-policy/">{this.props.locStrings.app_privacy_policy}</Link>
            </div>
          </div>
          <div className='baseFont elCopyright' style={style_elCopyright}>
            <div className="bottomAlignedContent">{`© ${new Date().getFullYear()} ${this.props.locStrings.copyright}`}</div>
          </div>
        </div>
      </div>
    )
  }


}
