import DataSheetBase from './DataSheetBase.js';
import datasheet_app_wordsmart_gre from './images/apps/WordSmart_GRE_Logo.svg';
import datasheet_app_wordsmart_sat_1 from './images/apps/WordSmart_SAT_1_Logo.svg';

export default class DataSheet_apps extends DataSheetBase {

  constructor(id, updateCb) {
    super(id, updateCb);
    this.requestedKeyPath = "";  // this value can be specified in the React Studio data sheet UI
  }

  makeDefaultItems() {
    // eslint-disable-next-line no-unused-vars
    let key = 1;
    // eslint-disable-next-line no-unused-vars
    let item;

    item = {};
    this.items.push(item);
    item['name'] = 'WordSmart prep for GRE®';
    item['image'] = datasheet_app_wordsmart_gre;
    item['app_store_url'] = 'https://itunes.apple.com/us/app/wordsmart-pre-for-gre/id1465397338';
    item['google_store_url'] = 'https://play.google.com/store/apps/details?id=com.dinowordsmartlite';
    item.key = key++;

    item = {};
    this.items.push(item);
    item['name'] = "WordSmart prep for SAT® (Set 1)";
    item['image'] = datasheet_app_wordsmart_sat_1;
    item['app_store_url'] = 'https://apps.apple.com/app/id1510257201';
    item['google_store_url'] = 'https://play.google.com/store/apps/details?id=com.wordsmartsat1';
    item.key = key++;
  }

}
