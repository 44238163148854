import React, {Component} from 'react';
import Slogantop from '../../Components/Slogantop/Slogantop';
import OurApps from '../../Components/OurApps/OurApps';
import Apps from '../../Components/OurApps/Apps';
import OurTeam from '../../Components/Team/OurTeam';
import TeamMember from '../../Components/Team/TeamMember';
import OurMission from '../../Components/Mission/OurMission';
import WhatWeDo from '../../Components/Mission/WhatWeDo';
import HowWeDoIt from '../../Components/Mission/HowWeDoIt';
import OurPassion from '../../Components/Passion/OurPassion';
import AboutOurPassion from '../../Components/Passion/AboutOurPassion';
import ContactUs from '../../Components/ContactUs/ContactUs';
import Map from '../../Components/Map/Map';
import SocialLinks from '../../Components/SocialLinks/SocialLinks';
import Menu from '../../Components/Menu/Menu';
import Logo from '../../Components/Logo/Logo';
import btn_icon_up_arrow from '../../images/btn_icon_up_arrow.png';
import btn_icon_down_arrow from '../../images/btn_icon_down_arrow.png';
import Footer from "../Footer/Footer";

require('./styles.css');

export default class StartScreen extends Component {

  // Properties used by this component:
  // appActions, deviceInfo

  constructor(props) {
    super(props);

    this.state = {
      elMenu_visible: false,
      elMenuUp_visible: false,
      elMenuDown_visible: true,
    };
  }

  onClick_elFab = (ev) => {
    alert("This is a demo");

  };


  onClick_elMenuUp = (ev) => {
    this.setState({elMenu_visible: !this.state.elMenu_visible});
    this.setState({elMenuDown_visible: true});
    this.setState({elMenuUp_visible: false});
  };


  onClick_elMenuDown = (ev) => {
    this.setState({elMenu_visible: !this.state.elMenu_visible});
    this.setState({elMenuUp_visible: true});
    this.setState({elMenuDown_visible: false});
  };


  render() {
    // eslint-disable-next-line no-unused-vars
    let baseStyle = {};
    // eslint-disable-next-line no-unused-vars
    let layoutFlowStyle = {};
    if (this.props.transitionId && this.props.transitionId.length > 0 && this.props.atTopOfScreenStack && this.props.transitionForward) {
      baseStyle.animation = '0.25s ease-in-out ' + this.props.transitionId;
    }
    if (!this.props.atTopOfScreenStack) {
      layoutFlowStyle.height = '100vh';
      layoutFlowStyle.overflow = 'hidden';
    }

    const dataSheet_apps = this.props.dataSheets['apps'];
    const style_elAppsPics = {
      height: 'auto',  // This element is in scroll flow
    };
    // Source items and any special components used for list/grid element 'Apps pics'.
    let items_appsPics = [];
    let listComps_appsPics = {};
    items_appsPics = items_appsPics.concat(this.props.appActions.getDataSheet('apps').items);

    const dataSheet_team = this.props.dataSheets['team'];
    const style_elBackgroundShape = {
      background: 'rgba(255, 255, 255, 1.000)',
    };
    const style_elTeamPics = {
      height: 'auto',  // This element is in scroll flow
    };
    // Source items and any special components used for list/grid element 'Team pics'.
    let items_teamPics = [];
    let listComps_teamPics = {};
    items_teamPics = items_teamPics.concat(this.props.appActions.getDataSheet('team').items);

    const style_elFab = {
      display: 'block',
      color: '#fff',
      textAlign: 'left',
      backgroundColor: '#222835',
      textTransform: 'none',
      cursor: 'pointer',
      pointerEvents: 'auto',
    };
    const style_elMenu = {
      boxShadow: '0.0px 6.8px 48px rgba(0, 0, 0, 0.4500)',
    };
    const elMenu = this.state.elMenu_visible ? (
      <div className='hasNestedComps elMenu' style={style_elMenu}>
        <Menu {...this.props} ref={(el) => this._elMenu = el} appActions={this.props.appActions}
              deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings}/>
      </div>
    ) : null;
    const style_elTop = {
      width: '100%',
      height: '100%',
    };
    const style_elTop_outer = {
      backgroundColor: 'transparent',
    };
    const style_elMenuUp = {
      display: 'block',
      backgroundImage: 'url(' + btn_icon_up_arrow + ')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '88.865%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
    };
    const elMenuUp = this.state.elMenuUp_visible ? (
      <button className='actionFont elMenuUp' style={style_elMenuUp} onClick={this.onClick_elMenuUp}/>
    ) : null;
    const style_elMenuDown = {
      display: 'block',
      backgroundImage: 'url(' + btn_icon_down_arrow + ')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '89.763%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
    };
    const elMenuDown = this.state.elMenuDown_visible ? (
      <button className='actionFont elMenuDown' style={style_elMenuDown} onClick={this.onClick_elMenuDown}/>
    ) : null;

    return (
      <div className="AppScreen StartScreen" style={baseStyle}>
        <div className="background">
          <div className='containerMinHeight elBackgroundShape' style={style_elBackgroundShape}/>
        </div>
        <div className="layoutFlow" style={layoutFlowStyle}>
          <div className='hasNestedComps elSlogantop'>
            <div>
              <Slogantop {...this.props} ref={(el) => this._elSlogantop = el} appActions={this.props.appActions}
                         deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings}/>
            </div>
          </div>

          <div className='hasNestedComps elOurApps'>
            <div>
              <OurApps {...this.props} ref={(el) => this._elOurApps = el} appActions={this.props.appActions}
                       deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings}/>
            </div>
          </div>

          <div className='hasNestedComps elAppsPics'>
            <div style={style_elAppsPics}>
              {items_appsPics.map((row, index) => {
                let itemClasses = `gridItem cols1_${index % 1} cols2_${index % 2} cols3_${index % 3}`;
                let itemComp = (row._componentId) ? listComps_appsPics[row._componentId] :
                  <Apps dataSheetId={'apps'} dataSheetRow={row} {...{
                    'name': row['name'],
                    'image': row['image'],
                    'app_store_url': row['app_store_url'],
                    'google_store_url': row['google_store_url'],
                  }}
                        appActions={this.props.appActions} deviceInfo={this.props.deviceInfo}
                        locStrings={this.props.locStrings}/>;
                return (
                  <div className={itemClasses} key={row.key}>
                    {itemComp}
                  </div>
                )
              })}
              <div ref={(el) => this._elAppsPics_endMarker = el}/>
            </div>
          </div>

          <div className='hasNestedComps elTeamHeadline'>
            <div>
              <OurTeam {...this.props} ref={(el) => this._elTeamHeadline = el} appActions={this.props.appActions}
                       deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings}/>
            </div>
          </div>

          <div className='hasNestedComps elTeamPics'>
            <div style={style_elTeamPics}>
              {items_teamPics.map((row, index) => {
                let itemClasses = `gridItem cols1_${index % 1} cols2_${index % 2} cols3_${index % 3}`;
                let itemComp = (row._componentId) ? listComps_teamPics[row._componentId] :
                  <TeamMember dataSheetId={'team'} dataSheetRow={row} {...{
                    'name': row['name'],
                    'description': row['description'],
                    'image': row['image'],
                  }} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo}
                              locStrings={this.props.locStrings}/>;
                return (
                  <div className={itemClasses} key={row.key}>
                    {itemComp}
                  </div>
                )
              })}
              <div ref={(el) => this._elTeamPics_endMarker = el}/>
            </div>
          </div>

          <div className='hasNestedComps elOurMission'>
            <div>
              <OurMission {...this.props} ref={(el) => this._elOurMission = el} appActions={this.props.appActions}
                          deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings}/>
            </div>
          </div>

          <div className='hasNestedComps elWhatWeDo'>
            <div>
              <div className="col0">
                <WhatWeDo appActions={this.props.appActions} deviceInfo={this.props.deviceInfo}
                          locStrings={this.props.locStrings}/>
              </div>
              <div className="col1">
                <HowWeDoIt appActions={this.props.appActions} deviceInfo={this.props.deviceInfo}
                           locStrings={this.props.locStrings}/>
              </div>
            </div>
          </div>

          <div className='hasNestedComps elOurPassion'>
            <div>
              <OurPassion {...this.props} ref={(el) => this._elOurPassion = el} appActions={this.props.appActions}
                          deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings}/>
            </div>
          </div>

          <div className='hasNestedComps elAboutOurPassion'>
            <div>
              <AboutOurPassion {...this.props} ref={(el) => this._elAboutOurPassion = el}
                               appActions={this.props.appActions} deviceInfo={this.props.deviceInfo}
                               locStrings={this.props.locStrings}/>
            </div>
          </div>

          <div className='hasNestedComps elContactUs'>
            <div>
              <ContactUs {...this.props} ref={(el) => this._elContactUs = el} appActions={this.props.appActions}
                         deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings}/>
            </div>
          </div>

          <div className='hasNestedComps elMap'>
            <div>
              <Map {...this.props} ref={(el) => this._elMap = el} appActions={this.props.appActions}
                   deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings}/>
            </div>
          </div>

          <div className='hasNestedComps elSocial'>
            <div>
              <SocialLinks {...this.props} ref={(el) => this._elSocial = el} appActions={this.props.appActions}
                           deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings}/>
            </div>
          </div>
          <div className='hasNestedComps elSFooter'>
            <div>
              <Footer {...this.props} ref={(el) => this._elSocial = el} appActions={this.props.appActions}
                           deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings}/>
            </div>
          </div>

        </div>
        <div className="screenFgContainer">
          <div className="foreground">
            {/*{ elMenu }*/}
            <div className='hasNestedComps elTop' style={style_elTop_outer}>
              <div style={style_elTop}>
                <Logo {...this.props} ref={(el) => this._elTop = el} appActions={this.props.appActions}
                      deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings}/>
              </div>
            </div>
            {/*{ elMenuUp }*/}
            {/*{ elMenuDown }*/}
          </div>
        </div>
      </div>
    )
  }

}
