import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import Home from './Components/Home/Home';
import PrivacyPolicy from './Components/Policy/PrivacyPolicy';
import AppPrivacyPolicy from './Components/Policy/AppPrivacyPolicy';

export default class App extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Router>
        <Route path="/" exact component={Home}/>
        <Route path="/privacy-policy/" component={PrivacyPolicy}/>
        <Route path="/app-privacy-policy/" component={AppPrivacyPolicy}/>
      </Router>
    );
  }
}
