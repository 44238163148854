import DataSheetBase from './DataSheetBase.js';
import datasheet_team_namita from './images/team/namita.gif';
import datasheet_team_rachel from './images/team/rachel.gif';
import datasheet_team_jay from './images/team/jay.gif';

export default class DataSheet_team extends DataSheetBase {

  constructor(id, updateCb) {
    super(id, updateCb);
    this.requestedKeyPath = "";  // this value can be specified in the React Studio data sheet UI
  }

  makeDefaultItems() {
    // eslint-disable-next-line no-unused-vars
    let key = 1;
    // eslint-disable-next-line no-unused-vars
    let item;
    
    item = {};
    this.items.push(item);
    item['name'] = "Namita";
    item['image'] = datasheet_team_namita;
    item['description'] = "As an Office Manager with over 14 years of experience, " +
        "I have learnt the ins and outs of delivering \"words\" in appropriate context with effects. " +
        "After honing and executing these specialties to reach numerous people, I was honored to provide " +
        "technique to master these words with use of technology. Now, I spend the majority of my time " +
        "brainstorming these techniques and connecting with other professionals who are interested " +
        "to ease the learning process.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['name'] = "Rachel";
    item['image'] = datasheet_team_rachel;
    item['description'] = "Rachel Kavathe is an artist, landscape architect, and city planner. " +
        "Her work focuses on creative placemaking, and site-specific art installations. " +
        "Her studio is located in Columbus, Indiana. ";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['name'] = "Jay";
    item['image'] = datasheet_team_jay;
    item['description'] = "Being a passionate reader, Jay always had a strong interest and knowledge in vocabulary " +
        "and always striving to do something in innovative way. He has 20+ years of experience and finished" +
        " his Bachelors from BITS Pilani, India in mechanical engineering and has a Masters in " +
        "Automotive Systems Engineering from Kettering University. He had GRE® score of 100 percentile. " +
        "Jay owns 2 patents in PEM fuel cells and has 13 years of work experience with General Motors " +
        "and Cummins Inc. in the automotive research area.";
    item.key = key++;
  }

}
