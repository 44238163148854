import React, { Component } from 'react';
import img_elRoundimage from '../../images/Apps.png';
import img_elAppStoreImage from '../../images/svgs/apple-store-image.svg';
import img_elGoogleStoreImage from '../../images/svgs/google-store-image.svg';

require('./styles.css');

export default class Apps extends Component {

  // Properties used by this component:
  // name, description, image

  onClick_elMobileStore(url) {
    window.open(url, '_blank');
  }

  render() {
    // eslint-disable-next-line no-unused-vars
    let baseStyle = {};
    // eslint-disable-next-line no-unused-vars
    let layoutFlowStyle = {};
    
    const style_elBackgroundShape = {
        background: 'rgba(255, 255, 255, 1.000)',
     };
    const style_elRoundimage = {
        backgroundImage: 'url('+(this.props.image || img_elRoundimage)+')',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: '50% 50%',
        backgroundSize: 'cover',
     };
    const style_elText = {
        fontSize: 19.4,
        color: 'rgba(0, 0, 0, 0.8500)',
        textAlign: 'center',
     };
    const style_elMobileStore = {
      height: 'auto',
      cursor: 'pointer',
      pointerEvents: 'auto',
    };
    const value_text = this.props.name;
    
    const style_elSpacer = {
        background: 'rgba(254, 255, 255, 1.000)',
     };
    
    return (
      <div className='Apps' style={baseStyle}>
        <div className='background'>
          <div className='containerMinHeight elBackgroundShape' style={style_elBackgroundShape} />
        </div>
        <div className='layoutFlow'>
          <div className='elRoundimage'>
            <div style={style_elRoundimage} />
          </div>
          
          <div className='font-openSansCondensedBold  elText'>
            <div style={style_elText}>
              <div>{value_text !== undefined ? value_text : (<span className='propValueMissing'>{this.props.locStrings.teammember_text_1015414}</span>)}</div>
            </div>
          </div>

          <div className='elMobileStoreContainer'>
            <div className='elMobileStore'>
              <img className='elAppleStore' style={style_elMobileStore} src={img_elAppStoreImage} alt='' onClick={() => this.onClick_elMobileStore(this.props.app_store_url)} />
            </div>

            <div className='elMobileStore'>
              <img className='elGoogleStore' style={style_elMobileStore} src={img_elGoogleStoreImage} alt='' onClick={() => this.onClick_elMobileStore(this.props.google_store_url)} />
            </div>
          </div>

          <div className='elSpacer'>
            <div style={style_elSpacer} />
          </div>
        </div>
      </div>
    )
  }
  

}
