import React, { Component } from 'react';

require('./styles.css');

export default class Map extends Component {

  // This component doesn't use any properties

  render() {
    // eslint-disable-next-line no-unused-vars
    let baseStyle = {};
    // eslint-disable-next-line no-unused-vars
    let layoutFlowStyle = {};
    
    const style_elBackgroundShape = {
        background: 'rgba(255, 255, 255, 1.000)',
     };
    // Embedded HTML content for element 'embed'
    const htmlContent_embed = "<style>\n    .google-maps {\n        position: relative;\n        padding-bottom: 30%; // This is the aspect ratio\n        height: 0;\n        overflow: hidden;\n    }\n    .google-maps iframe {\n        position: absolute;\n        top: 0;\n        left: 0;\n        width: 100% !important;\n        height: 100% !important;\n    }\n</style>\n\n<div class=\"google-maps\">\n  <iframe src=\"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d404840.9828941164!2d-122.54523841872212!3d37.559300204319456!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x808f992d6b215487%3A0x9f46fee3a886e31d!2sFoster+City+Blvd%2C+Foster+City%2C+CA+94404!5e0!3m2!1sen!2sus!4v1558356059359!5m2!1sen!2sus\" width=\"600\" height=\"450\" frameborder=\"0\" style=\"border:0\" allowfullscreen></iframe>  \n\n</div>";
    
    
    const style_elEmbed_outer = {
        pointerEvents: 'auto',
     };
    const style_elText = {
        color: 'rgba(0, 0, 0, 0.8500)',
        textAlign: 'center',
     };
    
    return (
      <div className="Map" style={baseStyle}>
        <div className="background">
          <div className='containerMinHeight elBackgroundShape' style={style_elBackgroundShape} />
        </div>
        <div className="layoutFlow">
          <div className='embeddedContent elEmbed' style={style_elEmbed_outer}>
            <div>
              <div dangerouslySetInnerHTML={{__html: htmlContent_embed}}></div>
            </div>
          
          </div>
          
        </div>
        <div className="foreground">
          <div className='headlineFont elText' style={style_elText}>
            <div>{this.props.locStrings.map_text_895751}</div>
          </div>
        </div>
      </div>
    )
  }
}
