import React, { Component } from 'react';
import img_elYouTube from '../../images/SocialLinks_YouTube.png';
import img_elTwitter from '../../images/SocialLinks_Twitter.png';
import img_elFacebook from '../../images/SocialLinks_Facebook.png';
import img_elLinkedIn from '../../images/SocialLinks_LinkedIn.png';

require('./styles.css');

export default class SocialLinks extends Component {

  // This component doesn't use any properties

  onClick_elYouTube = (ev) => {
    window.open('https://www.youtube.com/channel/UCA1KDPmlhwobPN8tSL6gMCw', '_blank');
  };

  onClick_elTwitter = (ev) => {
    window.open('https://twitter.com/innovation_29', '_blank');
  };

  onClick_elLinkedIn = (ev) => {
    window.open('https://www.linkedin.com/company/innovation29', '_blank');
  };

  onClick_elFacebook = (ev) => {
    window.open('https://www.facebook.com/innovation29', '_blank');
  };
  
  render() {
    // eslint-disable-next-line no-unused-vars
    let baseStyle = {};
    // eslint-disable-next-line no-unused-vars
    let layoutFlowStyle = {};
    
    const style_elBackgroundShape = {
        background: 'rgba(255, 255, 255, 1.000)',
     };
    const style_elYouTube = {
        height: 'auto',
        cursor: 'pointer',
        pointerEvents: 'auto',
    };
    const style_elTwitter = {
        height: 'auto',
        cursor: 'pointer',
        pointerEvents: 'auto',
    };
    const style_elLinkedIn = {
        height: 'auto',
        cursor: 'pointer',
        pointerEvents: 'auto',
    };
    const style_elFacebook = {
        height: 'auto',
        cursor: 'pointer',
        pointerEvents: 'auto',
    };
    
    return (
      <div className="SocialLinks" style={baseStyle}>
        <div className="background">
          <div className='containerMinHeight elBackgroundShape' style={style_elBackgroundShape} />
        </div>
        <div className="foreground">
          <img className='elYouTube' style={style_elYouTube} src={img_elYouTube} alt="" onClick={this.onClick_elYouTube}  />
          <img className='elTwitter' style={style_elTwitter} src={img_elTwitter} alt="" onClick={this.onClick_elTwitter}  />
          <img className='elLinkedIn' style={style_elLinkedIn} src={img_elLinkedIn} alt="" onClick={this.onClick_elLinkedIn}  />
          <img className='elFacebook' style={style_elFacebook} src={img_elFacebook} alt="" onClick={this.onClick_elFacebook}  />
        </div>
      </div>
    )
  }
  

}
